import React from "react"
import { Grid, makeStyles } from "@material-ui/core"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { graphql } from "gatsby"
import MdxLayout from "../../shared/MdxLayout"

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(5),
    paddingTop: theme.spacing(10),
  },
}))

export default function ArticleLayout({ data }) {
  const classes = useStyles()
  const post = data.mdx
  const imagePath = data?.allFile?.edges[0]?.node?.childImageSharp?.fluid?.src

  return (
    <MdxLayout
      title={post.frontmatter.title}
      excerpt={post.excerpt}
      slug={post.fields.slug}
      imagePath={imagePath}
    >
      <Grid
        container
        direction={"column"}
        alignItems="center"
        className={classes.root}
      >
        <Grid item xs={10}>
          <MDXRenderer slug={post.fields.slug} frontmatter={post.frontmatter}>
            {/*<MDXRenderer>*/}
            {post.body}
          </MDXRenderer>
        </Grid>
      </Grid>
    </MdxLayout>
  )
}

export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date
        vimeoVideoId
        transcript
        tw_tags
      }
      body
      excerpt
      fields {
        slug
      }
    }
    allFile(filter: { name: { regex: $slug }, extension: { eq: "jpeg" } }) {
      edges {
        node {
          name
          sourceInstanceName
          childImageSharp {
            fluid {
              src
              srcSet
            }
          }
        }
      }
    }
  }
`
